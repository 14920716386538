import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';

import { CenteredPageComponent } from '@uis-common/page/centered-page/centered-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentDividerComponent } from '@uis-common/content-divider/content-divider.component';
import { UserGeneralInfoFormComponent } from '@uis-private/components/user-forms/user-general-info-form/user-general-info-form.component';
import { ButtonComponent } from '@uis-common/button/button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ForeignAddressFormComponent } from '@uis-private/components/user-forms/foreign-address-form/foreign-address-form.component';
import { UkrainianAddressFormComponent } from '@uis-private/components/user-forms/ukrainian-address-form/ukrainian-address-form.component';
import { RoleDataService } from '@uis-services/role-data/role-data.service';
import { getStudentForm, UserType } from '@uis-models/contract/user';

@Component({
  selector: 'uis-student-form',
  imports: [
    CenteredPageComponent,
    ReactiveFormsModule,
    ContentDividerComponent,
    UserGeneralInfoFormComponent,
    ButtonComponent,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ForeignAddressFormComponent,
    UkrainianAddressFormComponent,
  ],
  templateUrl: './student-form.component.html',
  styleUrls: ['./student-form.component.scss', '../user-forms-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentFormComponent {
  protected readonly can = inject(RoleDataService).can;

  @Input({ required: true }) studentForm!: ReturnType<typeof getStudentForm>;
  @Input() withAdminControls = false;

  addParent() {
    const parentFormArray = this.studentForm.controls.parents;
    if (parentFormArray.length >= 2) {
      return;
    }

    parentFormArray.addControl();
    parentFormArray.markAsDirty();
  }

  removeParent(index: number) {
    const parentFormArray = this.studentForm.controls.parents;
    if (parentFormArray.length <= 1) {
      return;
    }

    parentFormArray.removeAt(index);
    parentFormArray.markAsDirty();
  }

  protected readonly UserType = UserType;
}
