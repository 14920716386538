import {
  AbstractControl,
  FormControlStatus,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ContentChildren,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  Injector,
  Input,
  OnInit,
  QueryList,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AsyncPipe } from '@angular/common';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  delay,
  distinctUntilChanged,
  EMPTY,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { fadeInDownOnEnterAnimation } from 'angular-animations';
import {
  firstUisValidationErrorMessage,
  hasUisValidator,
} from '@uis-core/validators/uis-validators-helpers';
import { MatIconModule } from '@angular/material/icon';
import { FormControlSubscriptComponent } from '@uis-common/inputs/infrastrucure/form-control-subscript/form-control-subscript.component';

const SUBSCRIPT_ANIMATION_DURATION = 50;

@Component({
  selector: 'uis-form-field',
  styleUrls: ['form-field.component.scss'],
  templateUrl: 'form-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatTooltipModule,
    MatIconModule,
    AsyncPipe,
    FormControlSubscriptComponent,
  ],
  animations: [
    fadeInDownOnEnterAnimation({
      duration: SUBSCRIPT_ANIMATION_DURATION,
    }),
  ],
})
export class FormFieldComponent implements OnInit, AfterContentInit {
  private readonly cd = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly injector = inject(Injector);
  protected readonly parentNgForm = inject(NgForm, { optional: true });
  protected readonly parentFormGroupDirective = inject(FormGroupDirective, {
    optional: true,
  });
  protected readonly parentForm =
    this.parentFormGroupDirective ?? this.parentNgForm;
  @ContentChildren(NgControl, { descendants: true })
  ngControls!: QueryList<NgControl>;
  @ContentChildren(NgControl, { descendants: true, read: ElementRef })
  ngControlsElements!: QueryList<ElementRef>;
  @Input() tooltip?: string;
  @Input() label?: string;
  @Input() hint?: string;
  @Input() explicitSubmitTrigger?: Signal<boolean> | Observable<any>;
  explicitSubmitTrigger$ = new BehaviorSubject(false);

  ngOnInit() {
    if (this.explicitSubmitTrigger) {
      const normalizedEmitter =
        this.explicitSubmitTrigger instanceof Observable
          ? this.explicitSubmitTrigger
          : toObservable(this.explicitSubmitTrigger, {
              injector: this.injector,
            });

      normalizedEmitter
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((value) => {
          this.explicitSubmitTrigger$.next(value);
        });
    }
  }

  ngAfterContentInit() {
    this.ngControls.changes
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.ngControls.first),
      )
      .subscribe(() => {
        this.ngControl.set(this.ngControls.first);
        this.ngControlElement.set(this.ngControlsElements.first);
      });
  }

  public readonly parentFormSubmitted = toSignal(
    merge(
      this.parentForm?.ngSubmit?.pipe(map(() => true)) ?? EMPTY,
      this.explicitSubmitTrigger$,
    ),
    {
      initialValue: false,
    },
  );
  private readonly parentFormSubmittedEffect = effect(() => {
    const submitState = this.parentFormSubmitted();
    const submittedClass = 'ng-submitted';
    this.ngControlsElements?.forEach((elementRef) => {
      if (submitState) {
        elementRef.nativeElement.classList.add(submittedClass);
      } else {
        elementRef.nativeElement.classList.remove(submittedClass);
      }
    });
  });
  public readonly ngControl: WritableSignal<NgControl | undefined> =
    signal(undefined);
  public readonly ngControlElement: WritableSignal<ElementRef | undefined> =
    signal(undefined);
  public readonly controlTouched = toSignal(
    toObservable(this.ngControlElement).pipe(
      switchMap((control) =>
        /**
         * Better solution would be for control to explicitly notify on all of it's changes like touched,
         * but it will require building a complete custom wrapper/custom-controls infrastructure
         * and since we are using library solutions like angular material alongside custom inputs
         * this should do the job for now. Later, projects from NIT ecosystem should use
         * complete custom components library which will include all the necessary inputs
         * and wrapper infrastructure.
         */
        control?.nativeElement
          ? merge(
              fromEvent(control?.nativeElement, 'click'),
              fromEvent(control?.nativeElement, 'blur'),
              fromEvent(control?.nativeElement, 'focus'),
              fromEvent(control?.nativeElement, 'focusin'),
              fromEvent(control?.nativeElement, 'focusout'),
              this.ngControl()?.statusChanges as Observable<any>,
            ).pipe(
              map(() => !!this.ngControl()?.touched),
              distinctUntilChanged(),
            )
          : of(false),
      ),
    ),
    { initialValue: !!this.ngControl()?.touched },
  );
  public readonly formControl = computed(
    () => this.ngControl()?.control ?? undefined,
  );
  public readonly required = computed(() =>
    hasUisValidator(this.formControlState(), 'required'),
  );
  public readonly formControlValue = toSignal(
    toObservable(this.formControl).pipe(
      switchMap(
        (control) =>
          control?.valueChanges.pipe(startWith(control?.value)) ??
          of(undefined),
      ),
    ),
    { initialValue: this.formControl()?.value },
  );
  public readonly formControlStatus: Signal<FormControlStatus | undefined> =
    toSignal(
      toObservable(this.formControl).pipe(
        switchMap(
          (control) =>
            control?.statusChanges.pipe(startWith(control?.status)) ??
            of(undefined),
        ),
      ),
      { initialValue: this.formControl()?.status },
    );
  public readonly formControlState: Signal<AbstractControl | undefined> =
    computed(
      () => {
        this.formControlValue();
        this.formControlStatus();
        this.parentFormSubmitted();
        this.controlTouched();
        return this.formControl();
      },
      { equal: () => false },
    );
  public readonly firstValidationErrorMessage: Signal<string | undefined> =
    computed(() => {
      const formControl = this.formControlState();
      return firstUisValidationErrorMessage(formControl);
    });
  public readonly isErrorVisible = computed(
    () =>
      !!(
        this.firstValidationErrorMessage() &&
        (this.controlTouched() || this.parentFormSubmitted())
      ),
  );

  // For some reason NG0100 error happens with isErrorVisible signal on file delete
  public readonly isErrorVisible$ = toObservable(this.isErrorVisible).pipe(
    tap(() => this.cd.markForCheck()),
    delay(0),
  );
}
