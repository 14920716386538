import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export enum UisIcon {
  Copy = 'copy',
  PersonInSuite = 'person-in-suite',
  PersonWithGear = 'person-with-gear',
  PersonWithBook = 'person-with-book',
  EyeClosed = 'eye_closed',
  EyeOpened = 'eye_opened',
  Lock = 'lock',
  Mail = 'mail',
  Edit = 'edit',
  MailRounded = 'mail-rounded',
  Star = 'star',
  Phone = 'phone',
  BackPack = 'backpack',
  BookStack = 'books',
  BookOpen = 'book-open',
  BookOpenWhite = 'book-open-white',
  Checklist = 'checklist',
  ChecklistWhite = 'checklist-white',
  Comment = 'comment',
  Diagram = 'diagram',
  FolderClosed = 'folder-closed',
  FolderOpened = 'folder-opened',
  House = 'house',
  List = 'list',
  Clock = 'clock',
  ArrowRight = 'arrow-right',
  HouseOutline = 'house-outline',
  BuildingOutline = 'building-outline',
  MapMarkerOutline = 'map-marker-outline',
  PersonOutline = 'person-outline',
  ISPTaskStatusAvailable = 'isp-task-status-available',
  ISPTaskStatusNotAvailable = 'isp-task-status-not-available',
  ISPTaskStatusInProgress = 'isp-task-status-in-progress',
  ISPTaskStatusInReview = 'isp-task-status-in-review',
  ISPTaskStatusPreviousNotDone = 'isp-task-status-previous-not-done',
  ISPTaskStatusEvaluated = 'isp-task-status-evaluated',
  ISPTaskStatusRejected = 'isp-task-status-rejected',
  ISPTaskStatusRevision = 'isp-task-status-revision',
  ResourceFile = 'resource-file',
  ResourceArchive = 'resource-archive',
  ResourceBook = 'resource-book',
  ResourceLink = 'resource-link',
  ResourceDownload = 'resource-download',
  Logout = 'logout',
}

export const UisIconPath: {
  [key in UisIcon]: string;
} = {
  [UisIcon.Copy]: '/assets/icons/common/copy.svg',
  [UisIcon.PersonInSuite]: '/assets/icons/users/person-in-suite.svg',
  [UisIcon.PersonWithGear]: '/assets/icons/users/person-with-gear.svg',
  [UisIcon.PersonWithBook]: '/assets/icons/users/person-with-book.svg',
  [UisIcon.EyeClosed]: '/assets/icons/auth/eye-closed.svg',
  [UisIcon.EyeOpened]: '/assets/icons/auth/eye-opened.svg',
  [UisIcon.Lock]: '/assets/icons/auth/lock.svg',
  [UisIcon.Mail]: '/assets/icons/auth/mail.svg',
  [UisIcon.Edit]: '/assets/icons/common/edit.svg',
  [UisIcon.MailRounded]: '/assets/icons/common/mail-rounded.svg',
  [UisIcon.Phone]: '/assets/icons/common/phone.svg',
  [UisIcon.Star]: '/assets/icons/common/star.svg',
  [UisIcon.BackPack]: '/assets/icons/common/backpack.svg',
  [UisIcon.BookStack]: '/assets/icons/common/book-stack.svg',
  [UisIcon.BookOpen]: '/assets/icons/common/book-open.svg',
  [UisIcon.BookOpenWhite]: '/assets/icons/common/book-open-white.svg',
  [UisIcon.Checklist]: '/assets/icons/common/checklist.svg',
  [UisIcon.ChecklistWhite]: '/assets/icons/common/checklist-white.svg',
  [UisIcon.Comment]: '/assets/icons/common/comment.svg',
  [UisIcon.Diagram]: '/assets/icons/common/diagram.svg',
  [UisIcon.FolderClosed]: '/assets/icons/common/folder-closed.svg',
  [UisIcon.FolderOpened]: '/assets/icons/common/folder-opened.svg',
  [UisIcon.House]: '/assets/icons/common/house.svg',
  [UisIcon.List]: '/assets/icons/common/list.svg',
  [UisIcon.Clock]: '/assets/icons/common/clock.svg',
  [UisIcon.ArrowRight]: '/assets/icons/common/arrow-right.svg',
  [UisIcon.HouseOutline]: '/assets/icons/common/house-outline.svg',
  [UisIcon.BuildingOutline]: '/assets/icons/common/building-outline.svg',
  [UisIcon.MapMarkerOutline]: '/assets/icons/common/map-marker-outline.svg',
  [UisIcon.PersonOutline]: '/assets/icons/common/person-outline.svg',
  [UisIcon.ISPTaskStatusAvailable]: `/assets/icons/isp-status/available.svg`,
  [UisIcon.ISPTaskStatusNotAvailable]:
    '/assets/icons/isp-status/not-available.svg',
  [UisIcon.ISPTaskStatusInProgress]: '/assets/icons/isp-status/in-progress.svg',
  [UisIcon.ISPTaskStatusInReview]: '/assets/icons/isp-status/in-review.svg',
  [UisIcon.ISPTaskStatusPreviousNotDone]:
    '/assets/icons/isp-status/previous-not-done.svg',
  [UisIcon.ISPTaskStatusEvaluated]: '/assets/icons/isp-status/evaluated.svg',
  [UisIcon.ISPTaskStatusRevision]: '/assets/icons/isp-status/revision.svg',
  [UisIcon.ISPTaskStatusRejected]: '/assets/icons/isp-status/rejected.svg',
  [UisIcon.ResourceFile]: '/assets/icons/independent-work/resource-file.svg',
  [UisIcon.ResourceArchive]:
    '/assets/icons/independent-work/resource-archive.svg',
  [UisIcon.ResourceBook]: '/assets/icons/independent-work/resource-book.svg',
  [UisIcon.ResourceLink]: '/assets/icons/independent-work/resource-link.svg',
  [UisIcon.ResourceDownload]:
    '/assets/icons/independent-work/resource-download.svg',
  [UisIcon.Logout]: '/assets/icons/navigation/logout.svg',
};

@Injectable({
  providedIn: 'root',
})
export class UisMatIconRegistryService {
  private readonly matIconRegistry = inject(MatIconRegistry);
  private readonly sanitizer = inject(DomSanitizer);

  constructor() {
    Object.values(UisIcon).forEach((iconName) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.safeUrl(UisIconPath[iconName]),
      );
    });
  }

  private safeUrl(string: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(string);
  }
}
