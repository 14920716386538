<uis-centered-page [pageTitle]="pageTitleText()">
  <form [formGroup]="profileForm()!" (ngSubmit)="onSubmit()">
    @if (profileForm() === studentForm) {
      <uis-student-form [studentForm]="studentForm"></uis-student-form>
    }
    @if (profileForm() === employeeForm) {
      <uis-employee-form [employeeForm]="employeeForm"></uis-employee-form>
    }
    <section class="profile-form__submit-section">
      @if ($any(profileForm().controls).skipJoinApplicationFlow) {
        <mat-checkbox
          labelPosition="before"
          formControlName="skipJoinApplicationFlow"
          >Пропустити подання заявки на вступ до НІТ Євроленд
        </mat-checkbox>
      }
      @if ($any(profileForm().value).skipJoinApplicationFlow) {
        <div class="profile-form__submit-section__selects-wrapper">
          <uis-form-field label="Навчальний рік">
            <mat-form-field>
              <mat-select
                placeholder="Оберіть навчальний рік в який хочете вступити"
                formControlName="academicYear"
                [compareWith]="academicYearOptionsWith"
              >
                @for (
                  academicYearOption of academicYearOptionsValue();
                  track academicYearOption
                ) {
                  <mat-option [value]="academicYearOption">
                    {{ academicYearOption.displayName }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </uis-form-field>
          <uis-form-field label="Клас">
            <mat-form-field>
              <mat-select
                placeholder="Оберіть клас в який зарахувати учня"
                formControlName="classLevel"
              >
                @for (
                  classLevelOption of classLevelOptions;
                  track classLevelOption
                ) {
                  <mat-option [value]="classLevelOption">
                    {{ classLevelOption }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </uis-form-field>
        </div>
      }
      <uis-button
        class="profile-form__submit"
        type="submit"
        [disabled]="profileForm().pristine"
        >{{ submitButtonText() }}</uis-button
      >
    </section>
  </form>
</uis-centered-page>
