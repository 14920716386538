import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EducationalCenter } from '@uis-models/contract/user';
import { KendoDataQuery } from '@uis-core/classes/kendo-data-query';
import { Observable } from 'rxjs';
import { SearchResult } from '@uis-models/contract/search';
import { EducationalCentersEp } from '@uis-enums/endpoints';

@Injectable({
  providedIn: 'root',
})
export class EducationalCentersService {
  private readonly http = inject(HttpClient);

  getAllCenters(
    kendoQuery: KendoDataQuery = new KendoDataQuery(0, 0),
  ): Observable<SearchResult<EducationalCenter>> {
    return this.http.get<SearchResult<EducationalCenter>>(
      EducationalCentersEp.All(),
      {
        params: new HttpParams({ fromObject: kendoQuery.query }),
      },
    );
  }

  getCenterById(id: string): Observable<SearchResult<EducationalCenter>> {
    return this.http.get<SearchResult<EducationalCenter>>(
      EducationalCentersEp.ById(id),
    );
  }
}
