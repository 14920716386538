import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'uis-centered-page',
  imports: [],
  templateUrl: './centered-page.component.html',
  styleUrls: ['./centered-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CenteredPageComponent {
  @Input() pageTitle = '';
}
