import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InfoChipComponent } from '@uis-private/common/profile-view/components/info-chip/info-chip.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'uis-info-table',
  imports: [InfoChipComponent, RouterLink],
  templateUrl: './info-table.component.html',
  styleUrl: './info-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoTableComponent {
  @Input() items: InfoTableItem[] | undefined | null = [];
}

export type InfoTableItem = {
  label: string;
  displayedValue?: string | number | null;
  routerLink?: string | undefined | null;
  href?: string | undefined | null;
  queryParams?: any;
  noData?: string;
};
