import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  signal,
} from '@angular/core';

import { UisFile } from '@uis-models/contract/uis-file';
import {
  AllowedAudioMimeType,
  AllowedDocumentMimeType,
  AllowedImageMimeType,
  AllowedVideoMimeType,
} from '@uis-common/inputs/file-inputs/file-type-settings';

@Component({
  selector: 'uis-file-icon',
  imports: [],
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileIconComponent {
  @Input({
    required: true,
    alias: 'file',
  })
  set fileInput(file: UisFile<any> | null | undefined) {
    this.file.set(file);
  }

  protected readonly file = signal<UisFile | null | undefined>(null);
  protected readonly text = computed(
    () => this.file()?.fileName.split('.').at(-1) ?? 'file',
  );
  protected readonly colorClass = computed(() => {
    const file = this.file();
    if (!file) {
      return 'default';
    }

    if (
      Object.values(AllowedImageMimeType).includes(
        file.contentType as AllowedImageMimeType,
      )
    ) {
      return 'image';
    }
    if (
      Object.values(AllowedVideoMimeType).includes(
        file.contentType as AllowedVideoMimeType,
      )
    ) {
      return 'video';
    }
    if (
      Object.values(AllowedAudioMimeType).includes(
        file.contentType as AllowedAudioMimeType,
      )
    ) {
      return 'audio';
    }
    if (
      Object.values(AllowedDocumentMimeType).includes(
        file.contentType as AllowedDocumentMimeType,
      )
    ) {
      return 'document';
    }

    return 'default';
  });
}
