import {
  ChangeDetectionStrategy,
  Component,
  Input,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UisFile } from '@uis-models/contract/uis-file';
import { FileIconComponent } from '@uis-common/files/file-icon/file-icon.component';
import { FileSizePipe } from '@uis-pipes/file-size/file-size.pipe';
import { downloadFileByUrl } from '@uis-core/helpers/utils';

@Component({
  selector: 'uis-file-list',
  imports: [CommonModule, FileIconComponent, FileSizePipe],
  templateUrl: './file-list.component.html',
  styleUrl: './file-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListComponent {
  @Input({ alias: 'files', required: true })
  set filesInput(files: UisFile[]) {
    this.files.set(files);
  }

  protected readonly files = signal<UisFile[]>([]);
  @Input() clickBehavior: 'open' | 'download' = 'open';

  onFileClick(file: UisFile) {
    switch (this.clickBehavior) {
      case 'open':
        window.open(file.url);
        break;
      case 'download':
        downloadFileByUrl(file.url, file.fileName);
        break;
    }
  }
}
