import { UserService } from '@uis-services/user/user.service';
import { RoleDataService } from '@uis-services/role-data/role-data.service';
import { TimeService } from '@uis-services/time/time.service';
import { combineLatest, firstValueFrom, from, switchMap } from 'rxjs';
import {
  EnvironmentProviders,
  inject,
  Injector,
  provideAppInitializer,
  runInInjectionContext,
} from '@angular/core';
import { UisMatIconRegistryService } from '@uis-services/uis-mat-icon-registry/uis-mat-icon-registry.service';
import { IndividualStudyPlanService } from '@uis-services/individual-study-plan/individual-study-plan.service';

const initApp = () => {
  const injector = inject(Injector);

  // Injected to be instantiated
  const uisMatIconRegistryService = inject(UisMatIconRegistryService);

  const calls = () =>
    // To inject other services only after time synchronization call completed
    runInInjectionContext(injector, () => [
      inject(UserService).me$,
      inject(IndividualStudyPlanService).myIndividualStudyPlan$,
      inject(RoleDataService).userRolesData$,
      from(document.fonts.ready),
    ]);

  const complete = inject(TimeService)
    .synchronize()
    .pipe(switchMap(() => combineLatest(calls())));

  return () => firstValueFrom(complete);
};

export function provideUisAppInitializer(): EnvironmentProviders {
  return provideAppInitializer(() => {
    const initializerFn = initApp();
    return initializerFn();
  });
}
