import { UisEntity } from '@uis-models/contract/uis-entity';
import {
  getUserForeignAddressFormControls,
  getUserUkrainianAddressFormControls,
  UserAddress,
} from '@uis-models/contract/user-address';
import { Email } from '@uis-core/types/strings';
import { EmployeeWorkload, UserSex } from '@uis-core/enums/user';
import { Parent } from '@uis-models/contract/parent';
import { UisSubject } from '@uis-models/contract/subject';
import { FormControl } from '@angular/forms';
import { UisValidators } from '@uis-core/validators/validators';
import { UisFormGroup } from '@uis-core/forms/uis-form-group';
import { UisFormArray } from '@uis-core/forms/uis-form-array';
import { UisFile } from '@uis-models/contract/uis-file';
import { UserProfileFile } from '@uis-enums/file-types';
import { UisTrait } from '@uis-enums/permissions';
import { PermissionChecker } from '@uis-services/role-data/role-data.types';

export interface User extends UisEntity {
  profilePicture?: UisFile<UserProfileFile.ProfilePicture>;
  email: Email;
  isStudent: boolean;
  birthDay: Date;
  firstName: string;
  fullName: string;
  lastName: string;
  middleName?: string;
  phoneNumber?: string;
  sex: UserSex;
  isRegistrationComplete: boolean;
  roles: UserRole[];
  hasMandatoryRole: boolean;
  publicId: string;
  classLevel: number;
  educationalCenter: EducationalCenter;
}

export class User {
  constructor(user: User) {
    Object.assign(this, user);
    this.hasMandatoryRole = this.roles.some((role) => role.isMandatory);
  }
}

export interface UserSearchModel extends User {
  traits: UisTrait[];
}

export interface EmployeeSearchModel extends Employee {
  traits: UisTrait[];
}

export interface StudentSearchModel extends Student {
  traits: UisTrait[];
}

export interface Student extends User {
  parents: Parent[];
  ukrainianAddress: UserAddress;
  foreignAddress: UserAddress;
}

export interface StudentCreateRequest extends Partial<Student> {
  skipJoinApplicationFlow: boolean;
  classLevel?: number;
  academicYear?: number;
}

export function isStudent(user?: User | null): user is Student {
  return !!user?.isStudent;
}

export interface Employee extends User {
  workload: EmployeeWorkload;
  subjects: UisSubject[];
}

export function isEmployee(user?: User | null): user is Employee {
  return !user?.isStudent;
}

export interface UserRole extends UisEntity {
  displayName: string;
  isCustom: boolean;
  isStudent: boolean;
  isMandatory: boolean;
  traits: UisTrait[];
}

export interface EducationalCenter {
  id: string;
  name: string;
}

export function getUserGeneralInfoFormControls(
  params?: {
    requiredPhoneNumber?: boolean;
    birthDayDisabled?: boolean;
    profilePictureDisabled?: boolean;
    emailDisabled?: boolean;
    emailRequired?: boolean;
  },
  can?: PermissionChecker,
  userType?: UserType,
) {
  const defaultParams = {
    requiredPhoneNumber: true,
    birthDayDisabled: false,
    profilePictureDisabled: false,
    emailDisabled: false,
    emailRequired: true,
  };

  const appliedParams = { ...defaultParams, ...params };

  return {
    profilePicture:
      new FormControl<UisFile<UserProfileFile.ProfilePicture> | null>(
        {
          disabled: appliedParams.profilePictureDisabled,
          value: null,
        },
        [UisValidators.validateFileType(UserProfileFile.ProfilePicture)],
      ),
    firstName: new FormControl('', [
      UisValidators.required(),
      UisValidators.personNameEntry(),
    ]),
    lastName: new FormControl('', [
      UisValidators.required(),
      UisValidators.personNameEntry(),
    ]),
    middleName: new FormControl('', [UisValidators.personNameEntry()]),
    phoneNumber: new FormControl('', [
      UisValidators.requiredIf(() => appliedParams.requiredPhoneNumber),
      UisValidators.internationalPhoneNumber(),
    ]),
    email: new FormControl(
      { value: '', disabled: appliedParams.emailDisabled },
      appliedParams.emailRequired
        ? [UisValidators.required(), UisValidators.email()]
        : [UisValidators.email()],
    ),
    sex: new FormControl(null, [UisValidators.required()]),
    birthDay: new FormControl(
      { value: null, disabled: appliedParams.birthDayDisabled },
      [UisValidators.required(), UisValidators.dateInThePast()],
    ),
    ...(userType === UserType.Student && {
      isEducationalCenterStudent: new FormControl({
        value: false,
        disabled: !can?.Read.EducationalCenter.All(),
      }),
      educationalCenter: new FormControl<EducationalCenter | null>(
        {
          value: null,
          disabled: !can?.Read.EducationalCenter.All(),
        },
        [UisValidators.required()],
      ),
    }),
  };
}

export function getStudentForm(can: PermissionChecker, forAdmin = false) {
  const studentGeneralInfoConfig = {
    requiredPhoneNumber: false,
    emailDisabled: !forAdmin,
    emailRequired: true,
    profilePictureDisabled: false,
    birthDayDisabled: false,
  };

  const parentGeneralInfoConfig = {
    requiredPhoneNumber: true,
    emailDisabled: false,
    emailRequired: false,
    profilePictureDisabled: true,
    birthDayDisabled: true,
  };

  return new UisFormGroup({
    ...getUserGeneralInfoFormControls(
      studentGeneralInfoConfig,
      can,
      UserType.Student,
    ),
    parents: new UisFormArray(
      [
        new UisFormGroup(
          getUserGeneralInfoFormControls(parentGeneralInfoConfig),
        ),
      ],
      () =>
        new UisFormGroup(
          getUserGeneralInfoFormControls(parentGeneralInfoConfig),
        ),
    ),
    ukrainianAddress: new UisFormGroup(getUserUkrainianAddressFormControls()),
    foreignAddress: new UisFormGroup(getUserForeignAddressFormControls()),
  });
}

export function getEmployeeForm(forAdmin = false) {
  return new UisFormGroup({
    ...getUserGeneralInfoFormControls({
      birthDayDisabled: false,
      requiredPhoneNumber: true,
      profilePictureDisabled: false,
      emailDisabled: !forAdmin,
      emailRequired: true,
    }),
    workload: new FormControl({ value: null, disabled: !forAdmin }, [
      UisValidators.required(),
    ]),
    roles: new FormControl(
      { value: <UserRole[]>(<unknown>[]), disabled: !forAdmin },
      [UisValidators.minLength(1), UisValidators.required()],
    ),
    subjects: new FormControl([], [UisValidators.required()]),
  });
}

export function patchEducationalCenterControl(
  form: ReturnType<typeof getEmployeeForm | typeof getStudentForm>,
  educationalCenter: EducationalCenter | undefined,
  isEducationalCenterStudentControlValue: boolean | null,
) {
  const educationalCenterControl = form.controls.educationalCenter;

  if (isEducationalCenterStudentControlValue) {
    educationalCenterControl?.setValidators([UisValidators.required()]);
    educationalCenterControl?.updateValueAndValidity();

    if (educationalCenter) {
      educationalCenterControl?.patchValue(educationalCenter);
    }
  } else {
    educationalCenterControl?.patchValue(null);
    educationalCenterControl?.markAsUntouched();
    educationalCenterControl?.clearValidators();
  }
}

export enum UserType {
  Employee = 'employee',
  Student = 'student',
}
