import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  Input,
} from '@angular/core';

import { UserSex } from '@uis-enums/user';
import { FormFieldComponent } from '@uis-common/inputs/infrastrucure/form-field/form-field.component';
import { MatInputModule } from '@angular/material/input';
import { TrimValueDirective } from '@uis-directives/trim-value/trim-value.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { DatepickerComponent } from '@uis-common/inputs/datepicker/datepicker.component';
import { DigitsOnlyDirective } from '@uis-directives/digits-only/digits-only.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import {
  EducationalCenter,
  getUserGeneralInfoFormControls,
  UserType,
} from '@uis-models/contract/user';
import { UisFormGroup } from '@uis-core/forms/uis-form-group';
import { ProfilePictureInputComponent } from '@uis-common/inputs/file-inputs/profile-picture-input/profile-picture-input.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/autocomplete';
import { BehaviorSubject, filter, map, of, switchMap } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { EducationalCentersService } from '@uis-services/educational-centers/educational-centers.service';
import { UserService } from '@uis-services/user/user.service';
import { MatSelect } from '@angular/material/select';
import { RoleDataService } from '@uis-services/role-data/role-data.service';
import { SearchResult } from '@uis-models/contract/search';

@Component({
  selector: 'uis-user-general-info-form',
  imports: [
    FormFieldComponent,
    MatInputModule,
    TrimValueDirective,
    ReactiveFormsModule,
    DatepickerComponent,
    DigitsOnlyDirective,
    MatIconModule,
    MatRadioModule,
    ProfilePictureInputComponent,
    MatCheckbox,
    MatOption,
    MatSelect,
  ],
  templateUrl: './user-general-info-form.component.html',
  styleUrls: [
    './user-general-info-form.component.scss',
    '../user-forms-common.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGeneralInfoFormComponent {
  protected readonly educationalCentersService = inject(
    EducationalCentersService,
  );
  protected readonly userService = inject(UserService);
  protected readonly me = inject(UserService).me;
  protected readonly can = inject(RoleDataService).can;

  protected readonly birthdayCalendarInitialDate = new Date('01/01/2000');
  protected readonly birthdayCalendarMaxDate = new Date();

  @Input({ required: true }) generalInfoForm!: UisFormGroup<
    ReturnType<typeof getUserGeneralInfoFormControls>
  >;

  public userType = input<UserType | null>(null);

  protected readonly UserSex = UserSex;
  protected readonly UserType = UserType;

  public educationalCenterQuery$ =
    new BehaviorSubject<EducationalCenter | null>(
      this.me()?.educationalCenter ?? null,
    );

  protected readonly educationalCentersOptions = toSignal(
    toObservable(this.me).pipe(
      filter(Boolean),
      filter(() => this.userType() === UserType.Student),
      switchMap((me) => {
        const canReadAllCenters = this.can.Read.EducationalCenter.All();
        const educationalCenter = me.educationalCenter;

        return canReadAllCenters
          ? this.educationalCentersService.getAllCenters()
          : educationalCenter?.id
            ? this.educationalCentersService.getCenterById(educationalCenter.id)
            : of(new SearchResult<EducationalCenter>());
      }),
      map((response) => response.data ?? [response]),
    ),
  );

  protected readonly educationalCenterOptionsWith = (
    option: EducationalCenter | null,
    selection: EducationalCenter | null,
  ) => {
    return !!(option && selection && option.id === selection.id);
  };
}
