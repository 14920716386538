import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { getRandomElement } from '@uis-core/helpers/utils';
import { RouterInputs } from '@uis-types/objects';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClickToCopyDirective } from '@uis-directives/click-to-copy/click-to-copy.directive';

export enum NotFoundComponentInputs {
  FailedRoute = 'failedRoute',
}

@Component({
  selector: 'uis-not-found',
  imports: [MatTooltipModule, ClickToCopyDirective],
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent
  implements RouterInputs<NotFoundComponentInputs>
{
  kaomojies = [
    '¯\\_(ツ)_/¯',
    '(╯︵╰,)',
    '(╯_╰)',
    'o(TヘTo)',
    '(｡╯︵╰｡)',
    '(｡T ω T｡)',
    '(>_ <)',
    '(╥╯θ╰╥)',
    '.·´¯`(>▂<)´¯`·.',
    '(◞‸◟；)',
    '(⋟﹏⋞)',
  ] as const;

  randomKaomoji = getRandomElement(this.kaomojies);

  @Input() failedRoute: string = '';
}
