import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UisIcon } from '@uis-services/uis-mat-icon-registry/uis-mat-icon-registry.service';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'uis-timer',
  imports: [CommonModule, MatIcon],
  templateUrl: './timer.component.html',
  styleUrl: './timer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimerComponent {
  public time = input.required<string | null>();
  public isOverdue = input<boolean>(false);

  protected readonly UisIcon = UisIcon;
}
