<div [formGroup]="studentForm">
  <section class="user-form__section">
    <h3 class="user-form__section__title">
      <uis-content-divider>Особиста інформація учня</uis-content-divider>
    </h3>
    <div>
      <uis-user-general-info-form
        [generalInfoForm]="$any(studentForm)"
        [userType]="UserType.Student"
      ></uis-user-general-info-form>
    </div>
  </section>
  <section class="user-form__section">
    <h3 class="user-form__section__title">
      <uis-content-divider>Опікуни</uis-content-divider>
    </h3>
    <ng-container formArrayName="parents">
      @for (
        parent of studentForm.controls.parents.controls;
        track parent;
        let parentIndex = $index;
        let parentsLength = $count
      ) {
        <h4 class="student-form__parent-title">
          Опікун {{ parentIndex + 1 }}
          @if (parentsLength > 1) {
            <button
              mat-icon-button
              color="warn"
              (click)="removeParent(parentIndex)"
              matTooltip="Видалити опікуна"
            >
              <mat-icon class="student-form__parent-title__close-icon"
                >close
              </mat-icon>
            </button>
          }
        </h4>
        <div>
          <uis-user-general-info-form
            [generalInfoForm]="$any(parent)"
          ></uis-user-general-info-form>
        </div>
      }
    </ng-container>
    @if (studentForm.controls.parents.controls.length < 2) {
      <uis-button class="student-form__add-guardian" (click)="addParent()">
        Додати опікуна
      </uis-button>
    }
  </section>
  @if (can.Read.Address.Own()) {
    <section class="user-form__section">
      <h3 class="user-form__section__title">
        <uis-content-divider>Місце проживання в Україні</uis-content-divider>
      </h3>
      <uis-ukrainian-address-form
        [ukrainianAddressForm]="studentForm.controls.ukrainianAddress"
      ></uis-ukrainian-address-form>
    </section>
  }
  @if (can.Read.Address.Own()) {
    <section class="user-form__section">
      <h3 class="user-form__section__title">
        <uis-content-divider>Фактичне місце перебування</uis-content-divider>
      </h3>
      <uis-foreign-address-form
        [foreignAddressForm]="studentForm.controls.foreignAddress"
      ></uis-foreign-address-form>
    </section>
  }
</div>
