import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'uis-content-divider',
  imports: [],
  templateUrl: './content-divider.component.html',
  styleUrls: ['./content-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentDividerComponent {}
