import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { ButtonComponent } from '@uis-common/button/button.component';
import { HttpClient } from '@angular/common/http';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { filter, switchMap } from 'rxjs';
import { User } from '@uis-models/contract/user';
import { UserService } from '@uis-services/user/user.service';
import { PaymentsService } from '@uis-services/payments/payments.service';
import { LiqPayRequestData } from '@uis-models/contract/payment';

@Component({
  selector: 'uis-payments-dialog',
  imports: [
    CommonModule,
    MatDialogTitle,
    ButtonComponent,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    FormsModule,
  ],
  templateUrl: './payments-dialog.component.html',
  styleUrl: '../../../../../../content/common/dialog.common.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsDialogComponent {
  protected readonly http = inject(HttpClient);
  protected readonly userService = inject(UserService);
  protected readonly paymentsService = inject(PaymentsService);

  protected readonly me = this.userService.me as Signal<User>;

  protected readonly paymentData = toSignal(
    this.paymentsService.getPaymentData(),
  );

  protected readonly liqPayData = toSignal(
    toObservable(this.paymentData).pipe(
      filter(Boolean),
      switchMap((paymentData) => {
        const payload: LiqPayRequestData = {
          username: this.me().fullName,
          orderDescription: paymentData!.description,
          price: paymentData!.price,
        };

        return this.paymentsService.pay(payload);
      }),
    ),
  );

  protected readonly dialogSections = computed(() => {
    const paymentData = this.paymentData();
    const me = this.me();

    return [
      {
        title: 'Платіж',
        text: paymentData?.description,
      },
      {
        title: 'Дитина',
        text: me.fullName,
      },
      {
        title: 'Сума',
        text: `₴ ${paymentData?.price}`,
      },
    ];
  });
}
