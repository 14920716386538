export enum JoinApplicationFile {
  AuthorizedPersonsStatement = 'authorized-persons-statement',
  IdentityDocument = 'identity-document',
  ResidenceDocument = 'residence-document',
  StudentPersonalFile = 'student-personal-file',
  BasicEducationCertificate = 'basic-education-certificate',
  FirstSemFinalMarks = 'first-sem-final-marks',
  AcademicReference = 'academic-reference',
}

export const JoinApplicationFileLabels: {
  [key in JoinApplicationFile]: string;
} = {
  [JoinApplicationFile.AuthorizedPersonsStatement]: 'Заява уповноважених осіб',
  [JoinApplicationFile.IdentityDocument]: 'Документ що засвідчує особу',
  [JoinApplicationFile.ResidenceDocument]:
    'Копія документа тимчасового/постійного місця проживання',
  [JoinApplicationFile.StudentPersonalFile]: 'Оригінал особової справи учня',
  [JoinApplicationFile.BasicEducationCertificate]:
    'Свідоцтво про здобуття базової освіти',
  [JoinApplicationFile.FirstSemFinalMarks]: 'Табель за I семестр',
  [JoinApplicationFile.AcademicReference]: 'Академічна довідка',
};

export enum UserProfileFile {
  ProfilePicture = 'user-profile-picture',
}

export enum IspTaskFile {
  Assignment = 'individual-study-plan-item-task-assignment',
  IndependentWorkResource = 'independent-work-resource',
  Answer = 'individual-study-plan-item-task-assignment-answer',
  ReviewedAnswer = 'individual-study-plan-item-task-assignment-reviewed-answer',
}

export type UisFileType =
  | 'any'
  | JoinApplicationFile
  | UserProfileFile
  | IspTaskFile;
