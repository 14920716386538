import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  forwardRef,
  HostListener,
  inject,
  Input,
  signal,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { bytesToDisplayString, noop } from '@uis-core/helpers/utils';
import { isUisFile, UisFile } from '@uis-models/contract/uis-file';
import { UisFileType } from '@uis-enums/file-types';
import { DragDropDirective } from '@uis-directives/drag-drop/drag-drop.directive';
import {
  AllowedAudioMimeType,
  AllowedDocumentMimeType,
  AllowedFileMimeType,
  AllowedImageMimeType,
  AllowedVideoMimeType,
  getMimeTypeExtensionString,
  UisFileTypeSettingsRegistry,
} from '@uis-common/inputs/file-inputs/file-type-settings';
import { MessageService } from '@uis-services/message/message.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FileIconComponent } from '@uis-common/files/file-icon/file-icon.component';

@Component({
  selector: 'uis-file-input',
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FileIconComponent,
  ],
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
  host: {
    '[tabIndex]': 'disabled() ? -1 : 0',
    '(uisIsDragging)': 'this.isDragging.set($event)',
    '(uisDrop)': 'onDrop($any($event))',
  },
  hostDirectives: [
    { directive: DragDropDirective, outputs: ['uisIsDragging', 'uisDrop'] },
  ],
})
export class FileInputComponent implements ControlValueAccessor {
  protected readonly bytesToDisplayString = bytesToDisplayString;

  private readonly destroyRef = inject(DestroyRef);
  private readonly message = inject(MessageService);

  public readonly focused = signal(false);

  @ViewChild('htmlInput', { static: true })
  htmlFileInput!: ElementRef<HTMLInputElement>;

  @HostListener('focus', ['$event'])
  @HostListener('blur', ['$event'])
  focusHandler(event: FocusEvent) {
    this.focused.set(this.disabled() ? false : event.type === 'focus');
  }

  @HostListener('keydown', ['$event'])
  keyPressHandler(event: KeyboardEvent) {
    if (!this.focused()) {
      return;
    }

    if (event.key === 'Enter') {
      this.htmlFileInput.nativeElement.click();
    }
  }

  @Input({ alias: 'fileType', required: true }) set fileTypeInput(
    fileType: UisFileType | undefined | null,
  ) {
    this.fileType.set(fileType ?? 'any');
  }

  public readonly fileType = signal<UisFileType>('any');

  @Input('valueAsSingleFile') set valueAsSingleFileInput(asSingle: boolean) {
    this.valueAsSingleFile.set(asSingle);
  }

  private readonly valueAsSingleFile = signal(false);

  protected readonly fileTypeSettings = computed(
    () => UisFileTypeSettingsRegistry[this.fileType()],
  );

  protected readonly internalValue = signal<UisFile<UisFileType>[]>([]);

  protected readonly hasUploadError = computed(() =>
    this.internalValue().some((file) => file.uploadingError),
  );
  protected readonly totalFilesSizeLimit = computed(
    () => this.fileTypeSettings().maxTotalSize,
  );
  protected readonly totalFilesSize = computed(() =>
    this.internalValue().reduce(
      (totalFilesSize, file) => totalFilesSize + file.fileSize || 0,
      0,
    ),
  );
  protected readonly totalFilesSizeSpaceLeft = computed(() =>
    this.totalFilesSizeLimit()
      ? this.totalFilesSizeLimit() - this.totalFilesSize()
      : 0,
  );
  protected readonly isTotalFilesSizeLimitReached = computed(() =>
    this.totalFilesSizeLimit()
      ? this.totalFilesSize() >= this.totalFilesSizeLimit()
      : false,
  );
  protected readonly maxFileSizeLimit = computed(
    () => this.fileTypeSettings().maxFileSize,
  );
  protected readonly maxFilesLimit = computed(
    () => this.fileTypeSettings().maxFiles ?? 0,
  );
  protected readonly isMaxFilesLimitReached = computed(() =>
    this.maxFilesLimit()
      ? this.internalValue().length >= this.maxFilesLimit()
      : false,
  );
  protected readonly maxFileLimitLeft = computed(
    () => this.maxFilesLimit() - this.internalValue().length,
  );
  protected readonly isOneFileMax = computed(
    () => this.fileTypeSettings().maxFiles === 1,
  );
  protected readonly allowedMimeTypes = computed(
    () => this.fileTypeSettings().allowedMimeTypes,
  );
  protected readonly fileLimitationsString = computed(() => {
    const isOneFileMax = this.isOneFileMax();
    const maxFilesLimit = this.maxFilesLimit();
    const totalFilesSizeLimit = this.totalFilesSizeLimit();
    const maxFileSizeLimit = this.maxFileSizeLimit();
    const allowedMimeTypes = this.allowedMimeTypes();

    const totalFilesSizeLimitString = totalFilesSizeLimit
      ? bytesToDisplayString(totalFilesSizeLimit)
      : '';

    const maxFileSizeString = maxFileSizeLimit
      ? bytesToDisplayString(maxFileSizeLimit)
      : '';

    const allowedMimeTypesString = allowedMimeTypes.length
      ? allowedMimeTypes
          .map((mimeType) => getMimeTypeExtensionString(mimeType))
          .join(', ')
      : '';

    const perFileLimitationsString = `${
      isOneFileMax || !maxFilesLimit ? '' : maxFilesLimit.toString() + ' x '
    }${[maxFileSizeString, allowedMimeTypesString].filter((x) => x).join(' ')}`;

    return totalFilesSizeLimitString
      ? `${totalFilesSizeLimitString} (${perFileLimitationsString})`
      : perFileLimitationsString;
  });

  protected readonly htmlInputAcceptString = computed(() =>
    this.fileTypeSettings().allowedMimeTypes.join(', ').toLowerCase(),
  );

  public readonly disabled = signal(false);
  public readonly isDragging = signal(false);
  public readonly isDropAllowed = computed(
    () =>
      this.isDragging() &&
      (!(
        this.isMaxFilesLimitReached() || this.isTotalFilesSizeLimitReached()
      ) ||
        this.isOneFileMax()),
  );

  public get hasUploading() {
    return this.internalValue().some((file) => !file.isUploaded);
  }

  trackByFileName = (index: number, file: UisFile<UisFileType>) =>
    file.fileName;

  getFileMatIconText(file: UisFile<UisFileType>): string {
    if (
      Object.values(AllowedDocumentMimeType).includes(
        file.contentType as AllowedDocumentMimeType,
      )
    ) {
      return 'description';
    }

    if (
      Object.values(AllowedImageMimeType).includes(
        file.contentType as AllowedImageMimeType,
      )
    ) {
      return 'image';
    }

    if (
      Object.values(AllowedAudioMimeType).includes(
        file.contentType as AllowedAudioMimeType,
      )
    ) {
      return 'audio_file';
    }

    if (
      Object.values(AllowedVideoMimeType).includes(
        file.contentType as AllowedVideoMimeType,
      )
    ) {
      return 'video_file';
    }

    return 'description';
  }

  onChange = noop;
  onTouched = noop;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(files: UisFile<UisFileType> | UisFile<UisFileType>[]) {
    const isArray = Array.isArray(files);
    const normalizedFiles = isArray ? files : [files];
    this.internalValue.set(
      normalizedFiles
        .filter(isUisFile)
        .map((file) => new UisFile(file, this.fileType())),
    );
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled.set(isDisabled);
  }

  onDrop(files: File[]) {
    if (!this.disabled()) {
      this.onAddFiles(files);
    }
  }

  onHtmlInputChange(event: Event) {
    if (!this.disabled()) {
      this.onAddFiles(Array.from((event.target as any)['files']));
    }
    (event.target as any).value = null;
  }

  deleteFileFromValue(index: number) {
    this.internalValue.update((files) => {
      const newArray = [...files];
      newArray
        .splice(index, 1)
        .forEach((deletedFile) => deletedFile.stopUploading());
      return newArray;
    });
    this.updateControlValue();
  }

  addFilesToValue(newFiles: UisFile[]) {
    this.internalValue.update((currentValue) => {
      let newValue: UisFile[];
      if (this.isOneFileMax()) {
        newValue = newFiles.length ? [...newFiles] : [...currentValue];
      } else {
        newValue = [...currentValue, ...newFiles];
      }

      newValue.forEach((file) => {
        if (!file.isUploaded) {
          file
            .upload()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.updateControlValue());
        }
      });

      return newValue;
    });
  }

  onDelete(index: number) {
    if (this.disabled()) {
      return;
    }
    this.deleteFileFromValue(index);
  }

  onAddFiles(files: File[]) {
    if (!this.validateFileArray(files)) {
      return;
    }

    const validFiles = this.validateFileObjects(files).map(
      (file) => new UisFile(file, this.fileType()),
    );

    if (!validFiles.length) {
      return;
    }

    this.addFilesToValue(validFiles);
  }

  validateFileArray(files: File[]) {
    const totalNewFilesSize = files.reduce(
      (totalSize, file) => totalSize + file.size,
      0,
    );

    if (this.isOneFileMax()) {
      if (files.length > 1) {
        this.message.warn(
          'Обрано забагато файлів',
          `Можна завантажити лише один файл`,
        );
        return false;
      }
    } else {
      if (this.isMaxFilesLimitReached()) {
        this.message.warn('Додано максимальну кількість файлів');
        return false;
      }

      if (this.isTotalFilesSizeLimitReached()) {
        this.message.warn(`Додано максимальнинй об'єм файлів`);
        return false;
      }

      if (this.maxFilesLimit() && files.length > this.maxFileLimitLeft()) {
        this.message.warn(
          'Обрано забагато файлів',
          `Доступно для завантаження: ${this.maxFileLimitLeft()}`,
        );
        return false;
      }
    }

    if (
      this.totalFilesSizeLimit() &&
      totalNewFilesSize > this.totalFilesSizeSpaceLeft()
    ) {
      this.message.warn(
        `Обрано завеликий об'єм файлів (${bytesToDisplayString(
          totalNewFilesSize,
        )})`,
        `Можна завантажити ще ${bytesToDisplayString(
          this.totalFilesSizeSpaceLeft(),
        )}`,
      );
      return false;
    }

    return true;
  }

  validateFileObjects(originalFiles: File[]): File[] {
    const fileTypeSettings = this.fileTypeSettings();

    let tooLargeFiles: File[] = [];
    let invalidMimeTypeFiles: File[] = [];
    let validFiles: File[] = [];

    originalFiles.forEach((file) => {
      // Allowed mimeType
      if (
        fileTypeSettings.allowedMimeTypes.length &&
        !fileTypeSettings.allowedMimeTypes.includes(
          file.type as AllowedFileMimeType,
        )
      ) {
        invalidMimeTypeFiles.push(file);
        return;
      }

      // Allowed size
      if (
        fileTypeSettings.maxFileSize &&
        file.size > fileTypeSettings.maxFileSize
      ) {
        tooLargeFiles.push(file);
        return;
      }

      validFiles.push(file);
    });

    if (tooLargeFiles.length) {
      const fileNames = tooLargeFiles.map((file) => file.name).join(', ');
      const isSingleFile = tooLargeFiles.length === 1;
      const warnTitle = `Помилка завантаження ${
        isSingleFile ? 'файлу' : 'файлів'
      }`;
      const warnText = `${
        isSingleFile ? 'Файл' : 'Файли'
      } ${fileNames} не було додано, оскільки максимальний дозволений розмір файлу ${bytesToDisplayString(
        fileTypeSettings.maxFileSize,
      )}`;

      this.message.warn(warnTitle, warnText);
    }

    if (invalidMimeTypeFiles.length) {
      const fileNames = invalidMimeTypeFiles
        .map((file) => file.name)
        .join(', ');
      const isSingleFile = invalidMimeTypeFiles.length === 1;
      const isSingleAllowedMimeType =
        fileTypeSettings.allowedMimeTypes.length === 1;
      const warnTitle = `Помилка завантаження ${
        isSingleFile ? 'файлу' : 'файлів'
      }`;
      const warnText = `${
        isSingleFile ? 'Файл' : 'Файли'
      } ${fileNames} не було додано, оскільки ${
        isSingleAllowedMimeType ? 'дозволений тип' : 'дозволені типи'
      } файлів це ${fileTypeSettings.allowedMimeTypes
        .map(getMimeTypeExtensionString)
        .join(', ')} `;

      this.message.warn(warnTitle, warnText);
    }

    return validFiles;
  }

  updateControlValue() {
    if (this.disabled()) {
      return;
    }
    const internalValueUploadedFiles = this.internalValue().filter(
      (file) => file.isUploaded,
    );
    if (this.valueAsSingleFile() && internalValueUploadedFiles[0]) {
      this.onChange(internalValueUploadedFiles[0]);
    } else {
      this.onChange(internalValueUploadedFiles);
    }
    this.onTouched();
  }

  onFileClick(file: UisFile) {
    window.open(file.url);
  }
}
